.breadcrumbs {
    padding: rem( 12px ) 0;

    ul {
        > li {
            > .hyp {
                margin-left: 0;
                margin-right: 0;
            }

            + li {
                padding-left: 0;

                &::before {
                    color: gray( '400' );
                    @extend %font-awesome;
                    content: '\f105'; //= angle-right
                    font-size: rem( 10px );
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                    line-height: 1;

                    padding-left: rem( 4px );
                    padding-right: rem( 4px );
                    position: static;
                }
            }
        }
    }

    .colored-header-desktop & {
        padding-top: 0;
        padding-bottom: 0;

        .hyp {
            color: #fff;
        }

        li:last-child .hyp {
            color: rgba( #fff, .65 );
        }
    }
}

.back-wrapper + .breadcrumbs {
    padding-left: rem(24px);
    margin-left: rem(24px);
}
