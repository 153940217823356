/**
 * @see https://ppg-alamo-beta.sanastores.net/products/
 */
.grid-mode .product-tile {
    min-height: 0;

    & a {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    @supports( display: flex ) {
        display: flex;
        flex-direction: column;
    }

    overflow: hidden;
    padding: 0;

    border-radius: rem( 6px );
    border: 1px solid gray( '200' );

    .product-img {
        padding-top: 0;

        @supports( display: flex ) {
            width: 100%;
        }

        .hyp-thumbnail {
            width: auto;
        }

        .thumbnail {
            overflow: hidden;

            img {
                transition: transform 350ms;
            }
        }
    }

    .product-info {
        padding: rem( 12px ) rem( 16px );
        background-color: gray( '100' );

        @supports( display: flex ) {
            flex-grow: 1;
        }

        .product-description {

        }

        .product-title {
            height: auto;
            text-decoration: none;
        }

        .product-id-stock {

        }

        .product-id {
            color: inherit;
        }

        .prices {
            margin-bottom: 0;
        }

        .form-quantity {
            padding-top: rem( 12px );

            .tbx-quantity + .unit-of-measure {
                vertical-align: middle;
            }

            .ui-spinner-button {
                width: rem( 28px );
                height: rem( 40px );

                text-indent: 0;
                padding: 0;

                /*
                @supports( display: flex ) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                */

                &::before {
                    display: none;
                }

                .ui-icon {
                    text-align: center;
                    text-indent: inherit;

                    margin-top: 0;
                    margin-left: 0;

                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate( -50%, -50% );

                    &::before {
                        @extend %font-awesome;
                        color: #fff;
                        font-size: rem( 14px );
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 900;
                        line-height: 1;
                        text-indent: 0;
                        text-align: center;
                    }
                }

                &.ui-spinner-down {
                    .ui-icon {
                        &::before {
                            content: "\f068";
                        }
                    }
                }

                &.ui-spinner-up {
                    .ui-icon {
                        &::before {
                            content: "\f067";
                        }
                    }
                }
            }
        }
    }


    /**
     * Hover state
     */
    &:hover {
        border-radius: rem( 6px );
        box-shadow: none;

        .thumbnail {
            img {
                transform: scale( 1.07 );
            }
        }

        .product-info {
            color: #fff;
            background-color: theme-color( 'primary' );

            .product-title {
                color: inherit;
            }

            & .product-action .prices .lbl-price-adu a,
            & .form-quantity .lbl-uom,
            & .product-action .lbl-price {
              color: #fff;
            }
        }

        .form-quantity {
            .ui-spinner-button {
                background-color: darken( theme-color( 'primary' ), 9% );
            }

            .tbx-quantity {
                input {
                    color: #333;
                }
            }
        }

    }

}
