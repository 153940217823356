%btn {
    height: auto;
    min-height: 100%;
    //min-height: rem(38px);

    cursor: pointer;
    line-height: rem( 20px );
    text-align: left;
    text-shadow: none;
    text-transform: none;

    padding: rem( 8px ) rem( 12px );

    border: 1px solid;
    border-radius: rem( 4px );

    &::after {
      display: none;
    }
}

%btn--primary {
    @extend %btn;

    color: #fff;
    border-color: theme-color( 'primary' );
    background-color: theme-color( 'primary' );

    &:hover,
    &:focus,
    &.hover {
        border-color: darken( theme-color( 'primary' ), 9% );
        background-color: darken( theme-color( 'primary' ), 9% );
    }
}

.btn {
    @extend %btn;

    &--primary {
        @extend %btn--primary;
    }
    &--secondary {
        @extend %btn--primary;
        background-color: transparent;
        border: 1px solid theme-color('primary');
        color: theme-color('primary');
    }
    &--secondary {
        &:hover,
        &:focus,
        &.hover {
            color: white;
        }
    }

    &-medium {
        font-size: rem( 16px );
    }

    &-login {
        font-size: rem( 14px );
    }

    & #{&}-cnt {
        padding-right: rem( 16px );
        position: relative;
        display: flex;

        &::after {
            @extend %font-awesome;

            width: auto;
            height: auto;

            content: '\f054';
            font-family: 'Font Awesome 5 Pro';

            background: none;
            left: auto;
            line-height: unset;
        }
    }

    &-action {
        @extend %btn--primary;
    }

    &-big .btn-cnt::after {
      font-size: 1.1rem;
    }

    &-action.btn-add-to-basket {
        min-width: auto;
        padding: .5rem .75rem;
    }

    &.btn-paging {
      &.btn-page-prev, &.btn-page-next {
        min-height: rem( 24px );
        background-color: transparent!important;
      }

      &.btn-page-prev.btn-disabled::before, &.btn-page-next.btn-disabled::before {
        color: gray('600');
      }
    }

    &.btn-paging.btn-page-prev::before, &.btn-paging.btn-page-next::before {
      @extend %font-awesome;

      width: auto;
      height: auto;

      font-family: 'Font Awesome 5 Pro';

      background-image: none;
      line-height: .8;

      color: theme-color( 'primary' );
      font-weight: bold;
    }

    &.btn-paging.btn-page-prev::before {
      content: '\f053';
    }

    &.btn-paging.btn-page-next::before {
      content: '\f054';
    }
}

.more_info_button {
    @extend %btn--primary;
}

.productListBack {
    @extend %btn;
    @extend %btn--primary;

    display: inline-block;
    direction: rtl;

    border: 0;
}

.btn-group {
    .btn {
        + .btn {
            margin-left: rem( 12px );
        }
    }
}

.more_info_button.button_wide {
    display: flex;
    align-items: center;
    margin: auto;
    & .fa-shopping-basket {
      margin-left: rem(8px);
    }
}

.basket-action-wrapper .btn-checkout {
    width: auto;
}
