/**
 * Base
 */
.d-flex {
  display: flex !important; }

.align-items-center {
  align-items: center !important; }

.justify-content-center {
  justify-content: center !important; }

.flex-column {
  flex-direction: column !important; }

.breadcrumbs ul > li + li::before, .btn .btn-cnt::after, .btn.btn-paging.btn-page-prev::before, .btn.btn-paging.btn-page-next::before, .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button .ui-icon::before, .ppgLeftSidebarCategories .ppgLeftSidebarCategoryToggleIcon::before, .middle-header .search .btn-search::before, .top-info.phone-icon::before, .top-dropdown.login .opener::after, .language.top-dropdown .opener .selected-language::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased; }

.hidde-visually {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.p--none {
  padding: 0; }

.pt--none {
  padding-top: 0; }

.pb--none {
  padding-bottom: 0; }

.pv--none {
  padding-top: 0;
  padding-bottom: 0; }

.p--xsmall {
  padding: 1rem; }

.pt--xsmall {
  padding-top: 1rem; }

.pb--xsmall {
  padding-bottom: 1rem; }

.pv--xsmall {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.p--small {
  padding: 1.5rem; }

.pt--small {
  padding-top: 1.5rem; }

.pb--small {
  padding-bottom: 1.5rem; }

.pv--small {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.p--medium {
  padding: 2rem; }

.pt--medium {
  padding-top: 2rem; }

.pb--medium {
  padding-bottom: 2rem; }

.pv--medium {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.p--large {
  padding: 3rem; }

.pt--large {
  padding-top: 3rem; }

.pb--large {
  padding-bottom: 3rem; }

.pv--large {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.m--none {
  margin: 0; }

.mt--none {
  margin-top: 0; }

.mb--none {
  margin-bottom: 0; }

.mv--none {
  margin-top: 0;
  margin-bottom: 0; }

.m--xsmall {
  margin: 1rem; }

.mt--xsmall {
  margin-top: 1rem; }

.mb--xsmall {
  margin-bottom: 1rem; }

.mv--xsmall {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m--small {
  margin: 1.5rem; }

.mt--small {
  margin-top: 1.5rem; }

.mb--small {
  margin-bottom: 1.5rem; }

.mv--small {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.m--medium {
  margin: 2rem; }

.mt--medium {
  margin-top: 2rem; }

.mb--medium {
  margin-bottom: 2rem; }

.mv--medium {
  margin-top: 2rem;
  margin-bottom: 2rem; }

/**
 * Components
 */
.aside-links {
  margin-top: 8px; }

.breadcrumbs {
  padding: 0.75rem 0; }
  .breadcrumbs ul > li > .hyp {
    margin-left: 0;
    margin-right: 0; }
  .breadcrumbs ul > li + li {
    padding-left: 0; }
    .breadcrumbs ul > li + li::before {
      color: #bdbdbd;
      content: '\f105';
      font-size: 0.625rem;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      line-height: 1;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      position: static; }
  .colored-header-desktop .breadcrumbs {
    padding-top: 0;
    padding-bottom: 0; }
    .colored-header-desktop .breadcrumbs .hyp {
      color: #fff; }
    .colored-header-desktop .breadcrumbs li:last-child .hyp {
      color: rgba(255, 255, 255, 0.65); }

.back-wrapper + .breadcrumbs {
  padding-left: 1.5rem;
  margin-left: 1.5rem; }

.btn--primary, .btn--secondary, .btn-action, .more_info_button, .productListBack, .btn {
  height: auto;
  min-height: 100%;
  cursor: pointer;
  line-height: 1.25rem;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  padding: 0.5rem 0.75rem;
  border: 1px solid;
  border-radius: 0.25rem; }
  .btn--primary::after, .btn--secondary::after, .btn-action::after, .more_info_button::after, .productListBack::after, .btn::after {
    display: none; }

.btn--primary, .btn--secondary, .btn-action, .more_info_button, .productListBack {
  color: #fff;
  border-color: #39A144;
  background-color: #39A144; }
  .btn--primary:hover, .btn--secondary:hover, .btn-action:hover, .more_info_button:hover, .productListBack:hover, .btn--primary:focus, .btn--secondary:focus, .btn-action:focus, .more_info_button:focus, .productListBack:focus, .hover.btn--primary, .hover.btn--secondary, .hover.btn-action, .hover.more_info_button, .hover.productListBack {
    border-color: #2d7f36;
    background-color: #2d7f36; }

.btn--secondary {
  background-color: transparent;
  border: 1px solid #39A144;
  color: #39A144; }

.btn--secondary:hover, .btn--secondary:focus, .btn--secondary.hover {
  color: white; }

.btn-medium {
  font-size: 1rem; }

.btn-login {
  font-size: 0.875rem; }

.btn .btn-cnt {
  padding-right: 1rem;
  position: relative;
  display: flex; }
  .btn .btn-cnt::after {
    width: auto;
    height: auto;
    content: '\f054';
    font-family: 'Font Awesome 5 Pro';
    background: none;
    left: auto;
    line-height: unset; }

.btn-big .btn-cnt::after {
  font-size: 1.1rem; }

.btn-action.btn-add-to-basket {
  min-width: auto;
  padding: .5rem .75rem; }

.btn.btn-paging.btn-page-prev, .btn.btn-paging.btn-page-next {
  min-height: 1.5rem;
  background-color: transparent !important; }

.btn.btn-paging.btn-page-prev.btn-disabled::before, .btn.btn-paging.btn-page-next.btn-disabled::before {
  color: #757575; }

.btn.btn-paging.btn-page-prev::before, .btn.btn-paging.btn-page-next::before {
  width: auto;
  height: auto;
  font-family: 'Font Awesome 5 Pro';
  background-image: none;
  line-height: .8;
  color: #39A144;
  font-weight: bold; }

.btn.btn-paging.btn-page-prev::before {
  content: '\f053'; }

.btn.btn-paging.btn-page-next::before {
  content: '\f054'; }

.productListBack {
  display: inline-block;
  direction: rtl;
  border: 0; }

.btn-group .btn + .btn {
  margin-left: 0.75rem; }

.more_info_button.button_wide {
  display: flex;
  align-items: center;
  margin: auto; }
  .more_info_button.button_wide .fa-shopping-basket {
    margin-left: 0.5rem; }

.basket-action-wrapper .btn-checkout {
  width: auto; }

/**
 * Sidebar filter widget
 */
.image--wide {
  max-height: 20rem;
  overflow: hidden;
  position: relative;
  min-height: 20rem;
  margin-bottom: 0;
  margin-top: 3rem; }

.image--wide img {
  top: -30%;
  position: absolute;
  min-width: 100%; }

label {
  cursor: pointer; }

.tbx, .tar {
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: #eeeeee;
  background-color: #fff; }
  .tbx input, .tar input {
    height: 2.5rem; }
  .tbx.focused, .tar.focused {
    border-color: #39A144;
    box-shadow: 0 0 4px rgba(57, 161, 68, 0.35); }

span.chb ins {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  background: none; }

span.chb input:checked + ins {
  color: #39A144;
  font-family: 'FontAwesome';
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  display: inline-block; }
  span.chb input:checked + ins::before {
    content: '\f00c'; }

.paging-links {
  display: flex;
  align-items: center; }

.pager-selected.btn {
  color: #fff; }

.pager-list .btn {
  background-color: transparent;
  font-weight: 800; }

.pager-list .pager-selected.btn {
  background-color: #39A144; }

.pager-list .btn a {
  color: #757575;
  text-decoration: none; }

/**
 * @see https://ppg-alamo-beta.sanastores.net/products/
 */
.grid-mode .product-tile {
  min-height: 0;
  overflow: hidden;
  padding: 0;
  border-radius: 0.375rem;
  border: 1px solid #eeeeee;
  /**
     * Hover state
     */ }
  .grid-mode .product-tile a {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
  @supports (display: flex) {
    .grid-mode .product-tile {
      display: flex;
      flex-direction: column; } }
  .grid-mode .product-tile .product-img {
    padding-top: 0; }
    @supports (display: flex) {
      .grid-mode .product-tile .product-img {
        width: 100%; } }
    .grid-mode .product-tile .product-img .hyp-thumbnail {
      width: auto; }
    .grid-mode .product-tile .product-img .thumbnail {
      overflow: hidden; }
      .grid-mode .product-tile .product-img .thumbnail img {
        transition: transform 350ms; }
  .grid-mode .product-tile .product-info {
    padding: 0.75rem 1rem;
    background-color: whitesmoke; }
    @supports (display: flex) {
      .grid-mode .product-tile .product-info {
        flex-grow: 1; } }
    .grid-mode .product-tile .product-info .product-title {
      height: auto;
      text-decoration: none; }
    .grid-mode .product-tile .product-info .product-id {
      color: inherit; }
    .grid-mode .product-tile .product-info .prices {
      margin-bottom: 0; }
    .grid-mode .product-tile .product-info .form-quantity {
      padding-top: 0.75rem; }
      .grid-mode .product-tile .product-info .form-quantity .tbx-quantity + .unit-of-measure {
        vertical-align: middle; }
      .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button {
        width: 1.75rem;
        height: 2.5rem;
        text-indent: 0;
        padding: 0;
        /*
                @supports( display: flex ) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                */ }
        .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button::before {
          display: none; }
        .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button .ui-icon {
          text-align: center;
          text-indent: inherit;
          margin-top: 0;
          margin-left: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
          .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button .ui-icon::before {
            color: #fff;
            font-size: 0.875rem;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            line-height: 1;
            text-indent: 0;
            text-align: center; }
        .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button.ui-spinner-down .ui-icon::before {
          content: "\f068"; }
        .grid-mode .product-tile .product-info .form-quantity .ui-spinner-button.ui-spinner-up .ui-icon::before {
          content: "\f067"; }
  .grid-mode .product-tile:hover {
    border-radius: 0.375rem;
    box-shadow: none; }
    .grid-mode .product-tile:hover .thumbnail img {
      transform: scale(1.07); }
    .grid-mode .product-tile:hover .product-info {
      color: #fff;
      background-color: #39A144; }
      .grid-mode .product-tile:hover .product-info .product-title {
        color: inherit; }
      .grid-mode .product-tile:hover .product-info .product-action .prices .lbl-price-adu a,
      .grid-mode .product-tile:hover .product-info .form-quantity .lbl-uom,
      .grid-mode .product-tile:hover .product-info .product-action .lbl-price {
        color: #fff; }
    .grid-mode .product-tile:hover .form-quantity .ui-spinner-button {
      background-color: #2d7f36; }
    .grid-mode .product-tile:hover .form-quantity .tbx-quantity input {
      color: #333; }

/**
 * Layout
 */
.content .center .center {
  padding: 0; }

.content-flexipage-page .flexi-row {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media (min-width: 1024px) {
    .content-flexipage-page .flexi-row {
      padding-top: 3rem;
      padding-bottom: 3rem; } }
  .content-flexipage-page .flexi-row h1 {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 0;
    padding-top: 0; }
  .content-flexipage-page .flexi-row .banner {
    margin-top: -2rem; }
    @media (min-width: 1024px) {
      .content-flexipage-page .flexi-row .banner {
        margin-top: -3rem; } }

.content-flexipage-page .above-content {
  background-color: #004094;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw; }
  .content-flexipage-page .above-content .above-content-full-width {
    position: absolute;
    top: 0;
    left: 0; }

.flow-img-tiles .html-wrapper h3 {
  margin-top: 1rem; }

/**
 * Bootstrap v3 Grid Sizing MIXED with Bootstrap v4 Flexbox Grid
 */
.row {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.col-sm-1,
.col-md-1,
.col-lg-1 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 992px) {
  .col-md-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1200px) {
  .col-lg-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

.col-sm-2,
.col-md-2,
.col-lg-2 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 992px) {
  .col-md-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1200px) {
  .col-lg-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

.col-sm-3,
.col-md-3,
.col-lg-3 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 992px) {
  .col-md-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 1200px) {
  .col-lg-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%; } }

.col-sm-4,
.col-md-4,
.col-lg-4 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .col-md-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1200px) {
  .col-lg-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

.col-sm-5,
.col-md-5,
.col-lg-5 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 992px) {
  .col-md-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1200px) {
  .col-lg-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

.col-sm-6,
.col-md-6,
.col-lg-6 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .col-md-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 1200px) {
  .col-lg-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%; } }

.col-sm-7,
.col-md-7,
.col-lg-7 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .col-md-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1200px) {
  .col-lg-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

.col-sm-8,
.col-md-8,
.col-lg-8 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 992px) {
  .col-md-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1200px) {
  .col-lg-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

.col-sm-9,
.col-md-9,
.col-lg-9 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 992px) {
  .col-md-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 1200px) {
  .col-lg-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%; } }

.col-sm-10,
.col-md-10,
.col-lg-10 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .col-md-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1200px) {
  .col-lg-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

.col-sm-11,
.col-md-11,
.col-lg-11 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 992px) {
  .col-md-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

.col-sm-12,
.col-md-12,
.col-lg-12 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  position: relative; }

@media (min-width: 768px) {
  .col-sm-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-md-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-lg-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%; } }

.gray_heading3 {
  font-size: 1.8rem; }

.above-content .center {
  padding-top: 0; }

.above-content .breadcrumbs {
  padding-top: 0;
  padding-bottom: 0; }

.banner .wide-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .banner .wide-image .banner-content {
    max-width: 1000px;
    padding: 0 2%; }

.banner .banner-full-width {
  height: 28.125rem;
  background-color: whitesmoke; }
  .banner .banner-full-width .image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    .banner .banner-full-width .image img {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0; }

.banner .banner-content {
  text-align: left;
  padding-top: 7.5rem; }
  @supports (display: flex) {
    .banner .banner-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto;
      flex-direction: column;
      padding-top: 0; } }
  .banner .banner-content h1, .banner .banner-content h2 {
    max-width: 40rem;
    line-height: normal !important;
    text-shadow: none;
    color: white;
    /*margin-left: auto;
            margin-right: auto;*/
    margin-bottom: 1rem; }
    .banner .banner-content h1:last-child, .banner .banner-content h2:last-child {
      margin-bottom: 0; }
  .banner .banner-content h2 {
    position: relative;
    font-size: 1.5rem;
    margin-top: 0.75rem;
    font-weight: lighter;
    overflow: hidden;
    min-width: 250px; }
  .banner .banner-content h2:before {
    background-color: white;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 24%;
    margin-right: 0.75rem; }

.colored-header-desktop {
  text-align: center;
  width: 100%;
  min-height: 12.5rem;
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  background-size: cover;
  background-position: center; }
  .colored-header-desktop::before {
    background: none; }
  .colored-header-desktop[style*="pool images/banner.jpg"] {
    background-image: url("../../files/alamo-webshop-header-img.jpg") !important; }
  .colored-header-desktop .center {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .colored-header-desktop .combined-header {
    color: #fff;
    margin-top: 0; }
    .colored-header-desktop .combined-header .h1-holder h1 {
      font-size: 3rem; }
  .colored-header-desktop .h1-holder {
    color: inherit;
    padding-top: 0;
    margin-bottom: 0; }
    .colored-header-desktop .h1-holder h1, .colored-header-desktop .h1-holder h2, .colored-header-desktop .h1-holder h3, .colored-header-desktop .h1-holder h4 {
      color: inherit; }
    .colored-header-desktop .h1-holder h1 {
      font-size: 3.75rem; }
  .colored-header-desktop .combined-description {
    color: rgba(255, 255, 255, 0.85); }

.grid-mode .l-products-item:first-child:before,
.grid-mode .l-products-item:nth-of-type(3n + 1)::after {
  display: none; }

/**
 * Back button
 */
.productListBack {
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.5rem 0.75rem;
  border: 1px solid #eeeeee;
  border-radius: 0.25rem; }
  .productListBack:hover {
    color: #fff;
    border-color: #39A144;
    background-color: #39A144; }

/**
 * Page
 */
.productlist-list-page .content .columns-general {
  padding-top: 1rem;
  padding-bottom: 2rem; }
  @media (min-width: 768px) {
    .productlist-list-page .content .columns-general {
      padding-top: 2rem;
      padding-bottom: 3rem; } }
  @media (min-width: 1024px) {
    .productlist-list-page .content .columns-general {
      padding-top: 3rem;
      padding-bottom: 4rem; } }

.toggleCategories {
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-top: 0.875rem; }
  .toggleCategories:hover {
    color: #39A144; }

.ppgLeftSidebarCategories {
  margin-top: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 0.375rem; }
  .ppgLeftSidebarCategories:last-child {
    margin-bottom: 0; }
  .ppgLeftSidebarCategories a {
    color: #333;
    font-size: 0.875rem;
    text-decoration: none;
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
    .ppgLeftSidebarCategories a:hover {
      color: #39A144; }
  .ppgLeftSidebarCategories .ppgLeftSidebarCategoryToggleIcon {
    width: 2rem;
    height: auto;
    cursor: pointer;
    display: block;
    color: #bdbdbd;
    text-align: center;
    line-height: 2.5rem;
    vertical-align: middle;
    background-image: none;
    position: absolute;
    top: 0;
    right: 0; }
    .ppgLeftSidebarCategories .ppgLeftSidebarCategoryToggleIcon::before {
      content: '\f067';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400; }
    .ppgLeftSidebarCategories .ppgLeftSidebarCategoryToggleIcon.expanded::before {
      content: '\f068'; }
    .ppgLeftSidebarCategories .ppgLeftSidebarCategoryToggleIcon.noSubCategories {
      display: none; }
  .ppgLeftSidebarCategories div {
    position: relative; }
    .ppgLeftSidebarCategories div > .ppgCategoryRow {
      border-bottom: 1px solid #eeeeee; }
      .ppgLeftSidebarCategories div > .ppgCategoryRow .ppgLeftSidebarCategoryToggleIcon:not(.noSubCategories) + a {
        padding-right: 2rem; }
    .ppgLeftSidebarCategories div > .ppgLeftSidebarCategory {
      display: none; }
    .ppgLeftSidebarCategories div.currentCategory > .ppgCategoryRow {
      font-weight: bold; }
    .ppgLeftSidebarCategories div.currentCategory > .ppgCategoryRow {
      color: #fff;
      background-color: #39A144; }
      .ppgLeftSidebarCategories div.currentCategory > .ppgCategoryRow a {
        color: inherit; }
  .ppgLeftSidebarCategories > div > .ppgCategoryRow {
    padding-left: 0.625rem; }
  .ppgLeftSidebarCategories > div > .ppgLeftSidebarCategory > div > .ppgCategoryRow {
    padding-left: 1.25rem; }
  .ppgLeftSidebarCategories > div > .ppgLeftSidebarCategory > div > .ppgLeftSidebarCategory > div > .ppgCategoryRow {
    padding-left: 1.875rem; }
  .ppgLeftSidebarCategories > div > .ppgLeftSidebarCategory > div > .ppgLeftSidebarCategory > div > .ppgLeftSidebarCategory > div > .ppgCategoryRow {
    padding-left: 2.5rem; }

.site-footer .company-name {
  color: #39A144;
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem; }

.site-footer .company-address .street-address,
.site-footer .company-address .country {
  display: block; }

.site-footer .company-address .postal-code + .city {
  margin-left: 0.375rem; }

.site-footer .company-info {
  margin-top: 0.5rem; }

.site-footer .bottom .copyright {
  color: #757575; }

#newsletter-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #39A144; }
  @media (min-width: 1024px) {
    #newsletter-container {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  #newsletter-container .newsletter {
    text-align: center;
    display: block;
    margin: 0 auto; }
    #newsletter-container .newsletter #newsletterForm {
      color: #fff; }
    #newsletter-container .newsletter .font-h4 {
      color: inherit;
      font-size: 1.5rem;
      line-height: 1;
      padding-bottom: 0; }
      @media (min-width: 1024px) {
        #newsletter-container .newsletter .font-h4 {
          font-size: 2rem; } }
    #newsletter-container .newsletter .newsletter-line {
      display: flex;
      justify-content: center;
      margin-top: 2rem; }
      #newsletter-container .newsletter .newsletter-line li {
        width: auto;
        display: inline-block; }
      #newsletter-container .newsletter .newsletter-line .tbx {
        min-width: 15rem;
        border: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0; }
      #newsletter-container .newsletter .newsletter-line [class^="fa"] {
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        line-height: 2.5rem;
        text-align: center;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        background-color: rgba(0, 0, 0, 0.35); }
        #newsletter-container .newsletter .newsletter-line [class^="fa"]:hover {
          background-color: rgba(0, 0, 0, 0.5); }

.panel-footer {
  margin: 1rem 0; }
  .panel-footer .paging-control {
    padding: 0; }
  .panel-footer .text-right.btn-add-inside {
    padding: 0; }

.bottom-header {
  position: relative; }
  @media (min-width: 1024px) {
    .bottom-header {
      background-color: transparent !important; }
      .bottom-header::before, .bottom-header::after {
        width: 100%;
        content: '';
        display: block;
        position: absolute;
        left: 0; }
      .bottom-header::before {
        border-top: 1px solid #eeeeee; }
      .bottom-header::after {
        border-bottom: 1px solid #eeeeee; } }
  .bottom-header .nav-opener::before, .bottom-header .nav-opener::after {
    display: none; }
  .bottom-header .nav-opener [class^="fa"] {
    width: 100%;
    color: #39A144;
    font-size: 1.5rem;
    line-height: 3.75rem;
    text-align: center; }
  .nav-is-opened .bottom-header .nav-opener [class^="fa"]::before {
    content: "\f00d"; }
  .bottom-header .nav-item-root .hyp-top-lvl {
    font-size: 1.125rem;
    line-height: normal;
    padding: 1.25rem 0.75rem;
    border-color: transparent;
    text-transform: none;
    font-weight: normal; }
    .bottom-header .nav-item-root .hyp-top-lvl span::after {
      display: none; }
  .bottom-header .nav-item-root.hover .hyp-top-lvl {
    border-color: #eeeeee; }
  .bottom-header .nav-list {
    padding-top: 0;
    padding-bottom: 0; }
  .bottom-header .nav-item {
    user-select: none;
    padding-left: 0; }
    .bottom-header .nav-item.nav-item-sub {
      line-height: normal; }
      .bottom-header .nav-item.nav-item-sub .hyp {
        padding-left: 1rem;
        padding-right: 1rem; }
        .bottom-header .nav-item.nav-item-sub .hyp::before {
          display: none; }
      .bottom-header .nav-item.nav-item-sub:hover > .hyp {
        color: #39A144; }
    .bottom-header .nav-item.selected .hyp-top-lvl {
      color: #39A144; }

.middle-header {
  background-color: white;
  flex-wrap: nowrap; }
  @media (min-width: 1024px) {
    .middle-header {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem; } }
  .middle-header .nav-opener::before, .middle-header .nav-opener::after {
    display: none; }
  .middle-header .nav-opener [class^="fa"] {
    width: 100%;
    color: #39A144;
    font-size: 1.5rem;
    line-height: 3.75rem;
    text-align: center; }
  .nav-is-opened .middle-header .nav-opener [class^="fa"]::before {
    content: "\f00d"; }
  .middle-header .logo {
    margin-right: 0; }
    .middle-header .logo > a {
      padding: 0.75rem 0; }
    .middle-header .logo img {
      max-width: 15rem; }
  .middle-header .search {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
    .middle-header .search .search-wrapper {
      padding-right: 0; }
    .middle-header .search .tbx-main-search {
      padding-right: 2.625rem;
      border-color: #eeeeee;
      /*&.focused {
                border-radius: 0;
            }*/ }
    .middle-header .search .btn-search {
      color: #39A144;
      line-height: normal;
      text-shadow: none;
      min-width: 0;
      width: 2.625rem;
      height: 2.625rem;
      padding-left: 0;
      padding-right: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      position: absolute;
      top: 1px;
      right: 1px; }
      .middle-header .search .btn-search::before {
        width: auto;
        height: auto;
        margin-top: 0;
        background-image: none;
        content: '\f002';
        font-size: 0.875rem;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        line-height: 1; }
    .middle-header .search .ui-autocomplete {
      left: 0 !important;
      border-color: #eeeeee; }
  .middle-header .basket .over-summary {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border: 0; }
    .middle-header .basket .over-summary .hyp::before {
      display: none; }

.ui-mobile .middle-header {
  position: fixed; }

.nav-is-opened .middle-header {
  position: relative;
  flex-wrap: wrap;
  max-height: 3.875rem;
  margin-top: 0;
  margin-bottom: 0; }

.top-header {
  color: #fff;
  width: 100%;
  max-width: none;
  display: block;
  padding: 0; }
  @media (min-width: 1024px) {
    .top-header {
      background-color: #39A144; } }
  .top-header .top-header-inner {
    max-width: 1000px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center; }
  header.sticky-mode .top-header {
    background-color: transparent; }

.top-info {
  font-size: 0.75rem;
  line-height: 1.5rem; }
  .top-info.phone-icon::before {
    width: auto;
    height: auto;
    color: rgba(255, 255, 255, 0.5);
    content: '\f095';
    font-family: 'Font Awesome 5 Pro';
    font-size: 0.875rem;
    font-weight: 900;
    margin-right: 0.375rem;
    background-image: none;
    transform: scaleX(-1); }

.top-action .top-hyp {
  color: #fff; }
  .top-action .top-hyp + .top-hyp {
    border-left-color: rgba(255, 255, 255, 0.15); }

.top-dropdown {
  height: 3.75rem;
  margin-top: 0;
  margin-bottom: 0;
  border: 0; }
  @media (min-width: 1024px) {
    .top-dropdown {
      height: 2.125rem; } }
  .top-dropdown.login {
    border-left: 0; }
    .top-dropdown.login .opener {
      min-width: 1.25rem;
      height: 3.75rem;
      cursor: pointer;
      line-height: 3.75rem;
      text-align: center;
      padding-bottom: 0;
      padding-left: 1rem;
      padding-right: 1.5rem; }
      @media (min-width: 1024px) {
        .top-dropdown.login .opener {
          height: 2.125rem; } }
      @media (min-width: 1024px) {
        .top-dropdown.login .opener {
          line-height: 2.125rem; } }
      .top-dropdown.login .opener [class*="fa-user"] {
        color: #39A144;
        font-size: 0.875rem; }
        @media (min-width: 1024px) {
          .top-dropdown.login .opener [class*="fa-user"] {
            color: #fff; } }
      .top-dropdown.login .opener::before {
        display: none; }
      .top-dropdown.login .opener::after {
        height: 3.75rem;
        content: '\f107';
        color: rgba(0, 0, 0, 0.15);
        font-family: 'Font Awesome 5 Pro';
        font-size: 0.875rem;
        line-height: inherit;
        background-image: none; }
        @media (min-width: 1024px) {
          .top-dropdown.login .opener::after {
            height: 2.125rem; } }
    .top-dropdown.login .summary {
      width: 12.5rem;
      font-size: 0.875rem;
      padding: 1.25rem 1.5rem;
      border-color: #eeeeee; }
      .top-dropdown.login .summary .welcome {
        line-height: normal;
        margin: 0;
        padding-bottom: 1.25rem;
        border-bottom-color: #eeeeee; }
      .top-dropdown.login .summary .hyp,
      .top-dropdown.login .summary #logoutLink {
        line-height: normal;
        text-decoration: none;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0; }
        .top-dropdown.login .summary .hyp::before,
        .top-dropdown.login .summary #logoutLink::before {
          display: none; }
        .top-dropdown.login .summary .hyp:hover,
        .top-dropdown.login .summary #logoutLink:hover {
          color: #39A144;
          background-color: transparent; }
      .top-dropdown.login .summary .logout {
        line-height: normal;
        margin: 0; }
    .top-dropdown.login:hover .opener [class*="fa-user"] {
      color: #39A144; }
    .top-dropdown.login:hover .opener::after {
      color: rgba(0, 0, 0, 0.15);
      top: auto;
      bottom: auto; }
  header.sticky-mode .top-dropdown {
    height: 3.75rem; }
    header.sticky-mode .top-dropdown.login .opener {
      height: 3.75rem; }
      header.sticky-mode .top-dropdown.login .opener [class*="fa"] {
        color: #39A144;
        line-height: 3.75rem; }
      header.sticky-mode .top-dropdown.login .opener::after {
        height: 3.75rem;
        line-height: 3.75rem; }
    header.sticky-mode .top-dropdown:hover,
    header.sticky-mode .top-dropdown:hover .opener {
      height: 3.75rem; }

.basket {
  height: 3.75rem; }
  .basket .over-summary {
    height: 3.75rem;
    line-height: 3.625rem; }

header.sticky-mode .over-summary .hyp:before {
  display: none;
  background-color: #004094; }

.language.top-dropdown {
  color: #212121; }
  .language.top-dropdown .opener {
    display: flex; }
  .language.top-dropdown .summary .hyp .iso, .language.top-dropdown.opener .selected-language {
    text-transform: uppercase; }
  .language.top-dropdown .opener .selected-language {
    display: flex;
    align-items: center;
    margin-left: 1.125rem;
    text-transform: uppercase; }

.opener:after {
  background-image: none;
  display: none; }

.language.top-dropdown .opener .selected-language::after {
  margin-left: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
  content: '\f0dd';
  color: #212121;
  font-family: 'Font Awesome 5 Pro';
  font-size: 1rem;
  line-height: .8;
  font-weight: 900; }

/**
 * Pages
 */
.basket-details-page .basket-page {
  padding-bottom: 4rem; }

.basket-details-page .above-content {
  padding-top: 3rem;
  padding-bottom: 1rem; }

.basket-details-page .basket-page-cnt .panel-header {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 639px) {
    .basket-details-page .basket-page-cnt .panel-header {
      flex-wrap: wrap; } }

@media screen and (max-width: 639px) {
  .basket-details-page .basket-page-cnt h2 {
    display: block; } }

.basket-details-page .basket-b2b-mode .panel-header .btn-checkout {
  display: inline-flex; }

.checkout-orderaddress-page .checkout-page-cnt {
  padding-bottom: 4rem; }

.contactus-page {
  margin: 3rem 0; }

.contactus-content {
  display: flex; }
  @media (max-width: 768px) {
    .contactus-content {
      flex-direction: column-reverse; } }

.contactus-content .semicolumn {
  width: 40%; }
  @media (max-width: 768px) {
    .contactus-content .semicolumn {
      width: 100%; } }

.contactus-content__contact {
  width: 57%;
  margin-left: 3%; }
  @media (max-width: 768px) {
    .contactus-content__contact {
      width: 100%;
      margin-left: auto;
      margin-top: 3%; } }

.contactus-content__contact iframe {
  max-height: 14.5rem;
  max-width: 100%; }
  @media (max-width: 768px) {
    .contactus-content__contact iframe {
      min-width: 100%;
      max-width: none;
      width: 100%; } }

.contactus-content__contact .contact-info {
  margin-top: 1rem; }

.contactus-content .form-row .control {
  display: flex; }

.contactus-content .form-row .label {
  width: auto;
  min-width: 26%; }

.contactus-content .form-row .field {
  width: 70%; }

.contactus-content .form-button {
  padding-left: 29%; }

#loginPage {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media (min-width: 1024px) {
    #loginPage {
      padding-top: 3rem;
      padding-bottom: 3rem; } }

#newsPage .h1-holder, #newsOverviewPage .h1-holder, #News .h1-holder {
  padding-top: 0; }

#newsPage .columns-general, #newsOverviewPage .columns-general, #News .columns-general {
  padding-top: 3rem;
  padding-bottom: 4rem; }

#productListPage {
  text-align: right; }
  #productListPage h2 {
    display: none !important; }
  #productListPage .category-blocks-container {
    text-align: left; }

.product-info .product-description {
  text-align: initial; }

.gvi-name-value .name {
  width: 15%; }

.font-name-value .name {
  font-size: 0.9375rem; }

.ui-tabs-active .ui-tabs-anchor:after {
  background: #39A144; }

.ui-tabs .ui-tabs-nav li {
  font-size: 1.2em; }

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  text-transform: none;
  margin-top: 15px;
  font-family: inherit;
  font-weight: bold; }

.font-product-title {
  text-transform: none;
  margin-bottom: 16px; }
