.image {
    &--wide {
        max-height: 20rem;
        overflow: hidden;
        position: relative;
        min-height: 20rem;
        margin-bottom: 0;
        margin-top: rem(48px);
    }
    &--wide img {
        top: -30%;
        position: absolute;
        min-width: 100%;
    }
}
