.site-footer {
    .company-name {
        color: theme-color( 'primary' );
        font-size: rem( 14px );
        font-weight: bold;

        margin-top: rem( 24px );
        margin-bottom: rem( 8px );
    }

    .company-address {
        .street-address,
        .country {
            display: block;
        }

        .postal-code + .city {
            margin-left: rem( 6px );
        }
    }

    .company-info {
        margin-top: rem( 8px );
    }

    & .bottom .copyright {
      //color: red;
      color: gray('600');
    }
}
