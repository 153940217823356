$indent: 10px;

.toggleCategories {
    font-size: rem( 14px );

    cursor: pointer;
    margin-bottom: rem( 8px );
    margin-top: rem(14px);

    &:hover {
        color: theme-color( 'primary' );
    }
}

.ppgLeftSidebarCategories {
    margin-top: 0;
    margin-bottom: rem( 32px );
    &:last-child { margin-bottom: 0; }

    overflow: hidden;

    border: 1px solid gray( '200' );
    border-radius: rem( 6px );

    a {
        color: #333;
        font-size: rem( 14px );
        text-decoration: none;

        display: block;
        padding-top: rem( 8px );
        padding-bottom: rem( 8px );

        &:hover {
            color: theme-color( 'primary' );
        }
    }

    //= menu-item toggler
    .ppgLeftSidebarCategoryToggleIcon {
        width: rem( 32px );
        height: auto;

        cursor: pointer;
        display: block;

        color: gray( '400' );
        text-align: center;
        line-height: rem( 40px );
        vertical-align: middle;

        background-image: none;
        position: absolute;
        top: 0; right: 0;

        &::before {
            @extend %font-awesome !optional;

            content: '\f067'; //= plus
            font-family: 'Font Awesome 5 Pro';
            font-weight: 400;
        }

        &.expanded {
            &::before {
                content: '\f068'; //= minus
            }
        }

        &.noSubCategories {
            display: none;
        }
    }

    //=  all
    div {
        position: relative;

        //= menu-item
        > .ppgCategoryRow {
            border-bottom: 1px solid gray( '200' );

            .ppgLeftSidebarCategoryToggleIcon:not( .noSubCategories ) + a {
                padding-right: rem( 32px );
            }
        }

        // menu-item.sub-menu
        > .ppgLeftSidebarCategory {
            display: none;
        }

        //&.currentCategoryTopParent,
        &.currentCategory {
            > .ppgCategoryRow {
                font-weight: bold;
            }
        }

        &.currentCategory {
            > .ppgCategoryRow {
                color: #fff;
                background-color: theme-color( 'primary' );

                a {
                    color: inherit;
                }
            }
        }
    }

    //= lvl 1
    > div > .ppgCategoryRow {
        padding-left: rem( $indent * 1 );
    }

    //= lvl 2
    > div > .ppgLeftSidebarCategory > div > .ppgCategoryRow {
        padding-left: rem( $indent * 2 );
    }

    //= lvl 3
    > div > .ppgLeftSidebarCategory > div > .ppgLeftSidebarCategory > div > .ppgCategoryRow {
        padding-left: rem( $indent * 3 );
    }

    //= lvl 4
    > div > .ppgLeftSidebarCategory > div > .ppgLeftSidebarCategory > div > .ppgLeftSidebarCategory > div > .ppgCategoryRow {
        padding-left: rem( $indent * 4 );
    }
}
