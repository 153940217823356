.gvi-name-value .name {
    width: 15%;
}

.font-name-value .name {
  font-size: rem(15px);
}

.ui-tabs-active .ui-tabs-anchor:after {
    background: theme-color( 'primary' );
}

.ui-tabs .ui-tabs-nav li {
font-size: 1.2em;
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  text-transform: none;
  margin-top: 15px;
  font-family: inherit;
  font-weight: bold;
}

.font-product-title {
  text-transform: none;
  margin-bottom: 16px;
}