.basket-details-page {
    .basket-page {
        padding-bottom: rem(64px);
    }
    .above-content {
        padding-top: rem(48px);
        padding-bottom: rem(16px);
    }
    .basket-page-cnt .panel-header{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 639px) {
            flex-wrap: wrap;
        }
    }
    .basket-page-cnt h2 {
        @media screen and (max-width: 639px) {
            display: block;
        }
    }
    .basket-b2b-mode .panel-header .btn-checkout {
        display: inline-flex;
    }
}
