#newsletter-container {
    padding-top: rem( 48px );
    padding-bottom: rem( 48px );

    @media( min-width: 1024px ) {
        padding-top: rem( 64px );
        padding-bottom: rem( 64px );
    }

    background-color: theme-color( 'primary' );

    .newsletter {
        // min-width: rem( 400px );
        text-align: center;

        display: block;
        margin: 0 auto;

        #newsletterForm {
            color: #fff;
        }

        .font-h4 {
            color: inherit;
            font-size: rem( 24px );
            @media ( min-width: 1024px ) { font-size: rem( 32px ); }
            line-height: 1;

            padding-bottom: 0;
        }

        .newsletter-line {
            display: flex;
            justify-content: center;
            margin-top: rem( 32px );

            li {
                width: auto;
                display: inline-block;
            }

            .tbx {
                min-width: rem( 240px );

                border: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                margin-right: 0;
            }

            [class^="fa"] {
                width: rem( 40px );
                height: rem( 40px );

                cursor: pointer;
                line-height: rem( 40px );
                text-align: center;

                border-top-right-radius: rem( 4px );
                border-bottom-right-radius: rem( 4px );
                background-color: rgba( #000, .35 );

                &:hover {
                    background-color: rgba( #000, .5 );
                }
            }
        }
    }
}
