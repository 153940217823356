.pager-list {
  & .btn {
    background-color: transparent;
    font-weight: 800;
  }
  & .pager-selected.btn {
    background-color: theme-color( 'primary' );
  }
  &  .btn a {
    color: gray('600');
    text-decoration: none;
  }
}
