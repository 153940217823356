.contactus {
   &-page {
    margin: rem(48px) 0;
   }

   &-content {
    display: flex;
    @media ( max-width: 768px ) {
        flex-direction: column-reverse;;
    }
   }

   &-content .semicolumn {
    width: 40%;
    @media ( max-width: 768px ) {
        width: 100%;
    }
   }
   &-content__contact {
    width: 57%;
    margin-left: 3%;
    @media ( max-width: 768px ) {
        width: 100%;
        margin-left: auto;
        margin-top: 3%;
    }
   }
   &-content__contact iframe {
    max-height: 14.5rem;
    max-width: 100%;
    @media ( max-width: 768px ) {
        min-width: 100%;
        max-width: none;
        width: 100%;
    }
   }
   &-content__contact .contact-info {
     margin-top: 1rem;
   }
   &-content .form-row .control {
       display: flex;
   }
   &-content .form-row .label {
       width: auto;
       min-width: 26%;
   }
   &-content .form-row .field {
       width: 70%;
   }
   &-content .form-button {
    padding-left: 29%;
   }
}
