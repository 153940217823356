.content-flexipage-page {
    .flexi-row {
        padding-top: rem( 32px );
        padding-bottom: rem( 32px );

        @media ( min-width: 1024px ) {
            padding-top: rem( 48px );
            padding-bottom: rem( 48px );
        }

        h1 {
            font-size: rem( 32px );
            line-height: 1;

            margin-bottom: 0;
            padding-top: 0;
        }

        .banner {
            margin-top: rem( -32px );
            @media ( min-width: 1024px ) { margin-top: rem( -48px ); }
        }
    }

    .above-content {
      background-color: #004094;
      margin-left: -50vw;
      margin-right: -50vw;
      position: relative;
      left: 50%;
      right: 50%;
      width: 100vw;

        .above-content-full-width {
            position: absolute;
            top: 0; left: 0;
        }
    }
}
