#productListPage {
    text-align: right;

    h2 {
        display: none !important;
    }

    .category-blocks-container {
        text-align: left;
    }
}

.product-info .product-description {
    text-align: initial;
}
