// padding classes
.p {
    &--none {
        padding: 0;
    }
    &t--none {
        padding-top: 0;
    }
    &b--none {
        padding-bottom: 0;
    }
    &v--none {
        padding-top: 0;
        padding-bottom: 0;
    }
    &--xsmall {
        padding: rem(16px);
    }
    &t--xsmall {
        padding-top: rem(16px);
    }
    &b--xsmall {
        padding-bottom: rem(16px);
    }
    &v--xsmall {
        padding-top: rem(16px);
        padding-bottom: rem(16px);
    }
    &--small {
        padding: rem(24px);
    }
    &t--small {
        padding-top: rem(24px);
    }
    &b--small {
        padding-bottom: rem(24px);
    }
    &v--small {
        padding-top: rem(24px);
        padding-bottom: rem(24px);
    }
    &--medium {
        padding: rem(32px);
    }
    &t--medium {
        padding-top: rem(32px);
    }
    &b--medium {
        padding-bottom: rem(32px);
    }
    &v--medium {
        padding-top: rem(32px);
        padding-bottom: rem(32px);
    }
    &--large {
        padding: rem(48px);
    }
    &t--large {
        padding-top: rem(48px);
    }
    &b--large {
        padding-bottom: rem(48px);
    }
    &v--large {
        padding-top: rem(48px);
        padding-bottom: rem(48px);
    }
}

// margin classes
.m {
    &--none {
        margin: 0;
    }
    &t--none {
        margin-top: 0;
    }
    &b--none {
        margin-bottom: 0;
    }
    &v--none {
        margin-top: 0;
        margin-bottom: 0;
    }
    &--xsmall {
        margin: rem(16px);
    }
    &t--xsmall {
        margin-top: rem(16px);
    }
    &b--xsmall {
        margin-bottom: rem(16px);
    }
    &v--xsmall {
        margin-top: rem(16px);
        margin-bottom: rem(16px);
    }
    &--small {
        margin: rem(24px);
    }
    &t--small {
        margin-top: rem(24px);
    }
    &b--small {
        margin-bottom: rem(24px);
    }
    &v--small {
        margin-top: rem(24px);
        margin-bottom: rem(24px);
    }
    &--medium {
        margin: rem(32px);
    }
    &t--medium {
        margin-top: rem(32px);
    }
    &b--medium {
        margin-bottom: rem(32px);
    }
    &v--medium {
        margin-top: rem(32px);
        margin-bottom: rem(32px);
    }
}
