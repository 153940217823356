.banner {
    .wide-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        .banner-content {
            max-width: 1000px;
            padding: 0 2%;
        }
    }
    .banner-full-width {
        height: rem( 450px );
        background-color: gray( '100' );

        .image {
            width: 100%;
            height: 100%;

            margin: 0 auto;
            position: relative;

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            img {
                width: 100%;
                height: 100%;

                opacity: 0;

                position: absolute;
                top: 0; left: 0;
            }
        }
    }

    .banner-content {
        text-align: left;
        padding-top:rem( 120px );

        @supports( display: flex ) {
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin: 0 auto;
            flex-direction: column;

            padding-top: 0;
        }

        h1, h2 {
            max-width: rem( 640px );
            line-height: normal !important;
            text-shadow: none;
            color: white;

            /*margin-left: auto;
            margin-right: auto;*/

            margin-bottom: rem( 16px );
            &:last-child { margin-bottom: 0; }
        }

        h2 {
            position: relative;
            font-size: rem( 24px );
            margin-top: rem( 12px );
            font-weight: lighter;
            overflow: hidden;
            min-width: 250px;
        }
        h2:before {
            background-color: white;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 24%;
            margin-right: rem(12px);
        }
    }
}
