label {
    cursor: pointer;
}

.tbx, .tar {
    border-radius: rem( 4px );
    border-width: 1px;
    border-style: solid;
    border-color: gray( '200' );

    background-color: #fff;

    input {
        height: rem( 40px );
    }

    &.focused {
        border-color: theme-color( 'primary' );
        box-shadow: 0 0 4px rgba( theme-color( 'primary' ), .35 );
    }
}

span.chb {
    ins {
        border: 1px solid gray( '200' );
        border-radius: 3px;
        background: none;
    }

    input:checked + ins {
        color: theme-color( 'primary' );
        font-family: 'FontAwesome';
        font-size: 14px;
        line-height: 1;
        text-decoration: none;

        display: inline-block;

        &::before {
            content: '\f00c';
        }
    }
}
