.colored-header-desktop {
    text-align: center;

    width: 100%;
    min-height: rem( 200px );
    display: flex;
    align-items: center;

    background-color: gray( '100' );
    background-size: cover;
    background-position: center;

    &::before {
        background: none;
    }

    &[style*="pool images/banner.jpg"] {
        background-image: url( '../../files/alamo-webshop-header-img.jpg' ) !important;
    }

    .center {
        padding-top: rem( 24px );
        padding-bottom: rem( 24px );
    }

    .combined-header {
        color: #fff;
        margin-top: 0;

        .h1-holder h1 {
            font-size: rem( 48px );
        }
    }

    .h1-holder {
        color: inherit;

        padding-top: 0;
        margin-bottom: 0;

        h1, h2, h3, h4 {
            color: inherit;
        }

        h1 {
            font-size: rem( 60px );
        }
    }

    .combined-description {
        color: rgba( #fff, .85 );
    }
}
