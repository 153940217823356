.bottom-header {
    position: relative;

    @media( min-width: 1024px ) {
        background-color: transparent !important;

        &::before,
        &::after {
            width: 100%;

            content: '';
            display: block;

            position: absolute;
            left: 0;
        }

        &::before {
            border-top: 1px solid gray( '200' );
        }

        &::after {
            border-bottom: 1px solid gray( '200' );
        }
    }

    .nav-opener {
        &::before,
        &::after {
            display: none;
        }

        [class^="fa"] {
            width: 100%;

            color: theme-color( 'primary' );
            font-size: rem( 24px );
            line-height: rem( 60px );
            text-align: center;
        }

        .nav-is-opened & {
            [class^="fa"] {
                &::before {
                    content: "\f00d";
                }
            }
        }
    }

    .nav-item-root {
        .hyp-top-lvl {
            font-size: rem( 18px );
            line-height: normal;
            padding :rem( 20px ) rem( 12px );

            border-color: transparent;

            text-transform: none;
            font-weight: normal;

            span {
                &::after {
                    display: none;
                }
            }
        }

        &.hover {
            .hyp-top-lvl {
                border-color: gray( '200' );
            }
        }
    }

    .nav-list {
        padding-top: 0;
        padding-bottom: 0;
    }

    .nav-item {
        user-select: none;
        padding-left: 0;

        &.nav-item-sub {
            line-height: normal;
            //background-color: pink;

            .hyp {
                padding-left: rem( 16px );
                padding-right: rem( 16px );

                &::before {
                    display: none;
                }
            }

            &:hover {
                > .hyp {
                    color: theme-color( 'primary' );
                }
            }
        }

        &.selected {
            .hyp-top-lvl {
                color: theme-color( 'primary' );
            }
        }
    }
}
