.middle-header {
    background-color: white;
    flex-wrap: nowrap;
    @media( min-width: 1024px ) {
        margin-top: rem( 12px );
        margin-bottom: rem( 12px );
    }

    .nav-opener {
        &::before,
        &::after {
            display: none;
        }

        [class^="fa"] {
            width: 100%;

            color: theme-color( 'primary' );
            font-size: rem( 24px );
            line-height: rem( 60px );
            text-align: center;
        }

        .nav-is-opened & {
            [class^="fa"] {
                &::before {
                    content: "\f00d";
                }
            }
        }
    }

    .logo {
        margin-right: 0;

        > a {
            padding: rem( 12px ) 0;
        }

        img {
            max-width: rem( 240px );
        }
    }

    .search {
        margin-left: rem( 12px );
        margin-right: rem( 12px );

        .search-wrapper {
            padding-right: 0;
        }

        .tbx-main-search {
            padding-right: rem( 42px );

            border-color: gray( '200' );



            /*&.focused {
                border-radius: 0;
            }*/
        }

        .btn-search {
            color: theme-color( 'primary' );
            line-height: normal;
            text-shadow: none;

            min-width: 0;
            width: rem( 42px );
            height: rem( 42px );

            padding-left: 0;
            padding-right: 0;

            border: 0;
            border-radius: 0;
            background-color: transparent;

            position: absolute;
            top: 1px; right: 1px;

            &::before {
                width: auto;
                height: auto;
                margin-top: 0;
                background-image: none;

                @extend %font-awesome;
                content: '\f002'; //= search
                font-size: rem( 14px );
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                line-height: 1;
            }
        }

        .ui-autocomplete {
            left: 0 !important;
            border-color: gray( '200' );
        }
    }

    .basket {
        .over-summary {
            padding-right: rem( 24px );
            padding-left: rem( 24px );

            border: 0;

            .hyp {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.ui-mobile {
    .middle-header {
        position: fixed;
    }
}

.nav-is-opened {
    .middle-header {
        position: relative;
        flex-wrap: wrap;
        max-height: rem(62px);
        margin-top: 0;
        margin-bottom: 0;
    }
}
