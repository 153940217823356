.grid-mode .l-products-item:first-child:before,
.grid-mode .l-products-item:nth-of-type( 3n + 1 )::after {
    display: none;
}


/**
 * Back button
 */
.productListBack {
    font-size: rem( 14px );
    line-height: normal;

    padding: rem( 8px ) rem( 12px );
    border: 1px solid gray( '200' );

    border-radius: rem( 4px );

    &:hover {
        color: #fff;
        border-color: theme-color( 'primary' );
        background-color: theme-color( 'primary' );
    }
}


/**
 * Page
 */
.productlist-list-page {
    .content {
        .columns-general {
            padding-top: rem( 32px - 16px );
            padding-bottom: rem( 32px );

            @media ( min-width: 768px ) {
                padding-top: rem( 48px - 16px );
                padding-bottom: rem( 48px );
            }

            @media ( min-width: 1024px ) {
                padding-top: rem( 64px - 16px );
                padding-bottom: rem( 64px );
            }
        }
    }
}
