.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-center {
    justify-content: center !important;
}

.flex-column {
    flex-direction: column !important;
}
