.top-header {
    color: #fff;

    width: 100%;
    max-width: none;

    display: block;
    padding: 0;
    @media ( min-width: 1024px ) { background-color: theme-color( 'primary' ); }

    .top-header-inner {
        max-width: 1000px;

        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    header.sticky-mode & {
        background-color: transparent;
    }
}

.top-info {
    font-size: rem( 12px );
    line-height: rem( 24px );

    &.phone-icon {
        &::before {
            @extend %font-awesome;

            width: auto;
            height: auto;

            color: rgba( #fff, .5 );
            content: '\f095'; //=phone
            font-family: 'Font Awesome 5 Pro';
            font-size: rem( 14px );
            font-weight: 900;

            margin-right: rem( 6px );

            background-image: none;
            transform: scaleX( -1 );
        }
    }
}

.top-action {
    .top-hyp {
        color: #fff;

        + .top-hyp {
            border-left-color: rgba( #fff, .15 );
        }
    }
}

.top-dropdown {
    height: rem( 60px );
    @media( min-width: 1024px ) { height: rem( 34px ); }

    margin-top: 0;
    margin-bottom: 0;

    border: 0;

    &.login {
        border-left: 0;

        .opener {
            min-width: rem( 20px );
            height: rem( 60px );
            @media( min-width: 1024px ) { height: rem( 34px ); }

            cursor: pointer;
            line-height: rem( 60px );
            @media( min-width: 1024px ) { line-height: rem( 34px ); }
            text-align: center;

            padding-bottom: 0;
            padding-left: rem( 16px );
            padding-right: rem( 24px );

            [class*="fa-user"] {
                color: theme-color( 'primary' );
                @media( min-width: 1024px ) { color: #fff; }
                font-size: rem( 14px );
            }

            &::before {
                display: none;
            }

            &::after {
                @extend %font-awesome;

                height: rem( 60px );
                @media( min-width: 1024px ) { height: rem( 34px ); }

                content: '\f107'; //= angle-down
                color: rgba( #000, .15 );
                font-family: 'Font Awesome 5 Pro';
                font-size: rem( 14px );
                line-height: inherit;

                background-image: none;
            }
        }

        .summary {
            width: rem( 200px );
            font-size: rem( 14px );

            padding: rem( 20px ) rem( 24px );
            border-color: gray( '200' );

            .welcome {
                line-height: normal;

                margin: 0;
                padding-bottom: rem( 20px );

                border-bottom-color: gray( '200' );
            }

            .hyp,
            #logoutLink {
                line-height: normal;
                text-decoration: none;

                padding-top: rem( 8px );
                padding-bottom: rem( 8px );
                padding-left: 0;

                &::before {
                    display: none;
                }

                &:hover {
                    color: theme-color( 'primary' );
                    background-color: transparent;
                }
            }

            .logout {
                line-height: normal;
                margin: 0;
            }
        }

        &:hover {
            .opener {
                [class*="fa-user"] {
                    color: theme-color( 'primary' );
                }
                &::after {
                    color: rgba( #000, .15 );

                    top: auto;
                    bottom: auto;
                }
            }
        }
    }


    header.sticky-mode & {
        height: rem( 60px );

        &.login {
            .opener {
                height: rem( 60px );

                [class*="fa"] {
                    color: theme-color( 'primary' );
                    line-height: rem( 60px );
                }

                &::after {
                    height: rem( 60px );
                    line-height: rem( 60px );
                }
            }
        }

        &:hover,
        &:hover .opener {
            height: rem( 60px );
        }
    }
}

.basket {
    height: rem( 60px );

    .over-summary {
        height: rem( 60px );
        line-height: rem( 58px );
    }
}

header.sticky-mode .over-summary .hyp:before {
  display: none;
  background-color: #004094;
}

.language.top-dropdown {
  color: gray('900');
  & .opener {
    display: flex;
  }
  & .summary .hyp .iso, &.opener .selected-language {
    text-transform: uppercase;
  }
  & .opener .selected-language   {
      display: flex;
      align-items: center;
      margin-left: rem(18px);
      text-transform: uppercase;
  }
}

.opener:after {
  background-image: none;
  display: none;
}

.language.top-dropdown .opener .selected-language::after {
  @extend %font-awesome;
  margin-left: rem( 10px );
  width: rem( 18px );
  height: rem( 18px );

  content: '\f0dd'; //= sort-down
  color: gray('900');
  font-family: 'Font Awesome 5 Pro';
  font-size: rem( 16px );
  line-height: .8;
  font-weight: 900;
}
